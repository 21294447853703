import styled from "styled-components";
import { getColor } from "@website-builder/utilities-index";
import { MOBILE_L_BP } from "@website-builder/utilities-index";
const getVisibilityStyle = (hideOnDevices = []) => {
	let style = "";
	if (hideOnDevices?.includes("mobile")) {
		style += `
      @media only screen and (max-width: ${MOBILE_L_BP}px) {
        display: none;
      }
    `;
	}
	if (hideOnDevices?.includes("desktop")) {
		style += `
      @media only screen and (min-width: ${MOBILE_L_BP}px) {
        display: none;
      }
    `;
	}
	return style;
};
export const FullWidthWrapper = styled.section`
	${({ hideOnDevices }) => getVisibilityStyle(hideOnDevices)}
	background-size: cover;
	background-position: ${({ alignBackgroundImage }) => alignBackgroundImage};
	${(props) =>
		props.bgcolor.type && props.bgcolor.type === "custom"
			? `background-color: ${props.bgcolor.color};`
			: props.bgcolor.type
				? `background-color: ${getColor(props.bgcolor.type)};`
				: `background-color: ${getColor(
						props.bgcolor,
					)};`} //For Backward Compatibility
  ${(props) =>
		props.bluryImageUrl && `background-image: url(${props.bluryImageUrl});`}
  ${(props) =>
		props.desktopBackground &&
		`background-image: url(${props.desktopBackground});`}
    ${({ updateBottomSpacing }) =>
		(updateBottomSpacing === 0 || updateBottomSpacing) &&
		`
    .body {
      padding-bottom: 0px;
    }
    .outer-margin-wrap.outer-margin-wrap {
        padding-bottom: ${updateBottomSpacing}px;
    }`}
    ${({ updateTopSpacing }) =>
		(updateTopSpacing === 0 || updateTopSpacing) &&
		`
    .body {
      padding-top: 0px;
    }
    .outer-margin-wrap.outer-margin-wrap {
        padding-top: ${updateTopSpacing}px;
      }`}
  @media only screen and (max-width: ${MOBILE_L_BP}px) {
		${(props) =>
			props.mobileBackground &&
			`background-image: url(${props.mobileBackground});
     background-position: center;
    `}
	}
`;
