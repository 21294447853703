import React from "react";
import { FullWidthWrapper } from "./style";

const FullWidthSection = ({
	children,
	hashLink,
	hideInMobile,
	hideOnDevices,
	imageConfig,
	bgcolor,
	alignBackgroundImage = "center",
	mobileImageConfig,
	className = "",
	updateBottomSpacing,
	updateTopSpacing,
}) => {
	const { bluryImageUrl, lazyImageUrl } = imageConfig;
	return (
		<FullWidthWrapper
			id={hashLink ? `${hashLink}` : ""}
			className={`lazyload row ${className}`}
			bluryImageUrl={bluryImageUrl}
			bgcolor={bgcolor}
			hideInMobile={hideInMobile}
			hideOnDevices={hideOnDevices}
			desktopBackground={lazyImageUrl}
			mobileBackground={mobileImageConfig?.lazyImageUrl}
			updateBottomSpacing={updateBottomSpacing}
			updateTopSpacing={updateTopSpacing}
			alignBackgroundImage={alignBackgroundImage}
		>
			{children}
		</FullWidthWrapper>
	);
};

export default FullWidthSection;
